// import LeftImg from "../../assets/images/blog_eye-opening-mainframe-computers.jpg";
// import React from "react";
// import { exploreUsLeft } from "../../constants/index";
// import "./index.css";
// import 'aos/dist/aos.css';

// const Explore = () => {

//   return (
//     <div className="explore_section" data-aos="fade-right">
//       <h2 className="explore_heading" data-aos="fade-left">
//         <span>Explore </span>
//         <span className="color">Us</span>
//       </h2>
//       <p className="explore_para">
//         {" "}
//         At MCDMF, we specialize in providing expert consultation and innovative
// strategies for the modernization of mainframe applications. Our core
// mission is to assist organizations in transforming their legacy mainframe
// systems into agile, efficient, and future-ready platforms. We deliver
// comprehensive project management services to ensure seamless
// execution and achievement of modernization objectives.
//       </p>
//       <div className="explore_section_container">
//         <div className="left_card">
//           <div className="card">
//             <img src={LeftImg} alt="left_Image" />

//             <div className="content">
//               <h2 className="content_heading">Innovation.</h2>
//               <p className="content_para">
//                 Innovation turns dreams into reality, bridging imagination with
//                 tangible outcomes. It's the force that propels us forward,
//                 creating solutions and shaping the future, guiding humanity
//                 towards endless possibilities and transformative change.
//                 Turning bold ideas into breakthrough realities.
//               </p>
//             </div>
//           </div>
//         </div>
//         <div className="right_card">
//           {exploreUsLeft.map((item) => (
//             <div className="card">
//               <img src={item.img} alt="left_Image" />
//               <div className="content">
//                 <h2 className="content_heading">{item.title}</h2>
//                 <p className="content_para">{item.description}</p>
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Explore;

import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Navigation, Autoplay } from 'swiper/modules';
import LeftImg from "../../assets/images/innovation(1).jpg";
import { exploreUsLeft } from "../../constants/index";
import "./index.css";
import 'aos/dist/aos.css';

const Explore = () => {
  return (
    <div className="explore_section" data-aos="fade-right">
      <h2 className="explore_heading" data-aos="fade-left">
        <span>Explore </span>
        <span className="color">Us</span>
      </h2>
      <p className="explore_para">
        At MCDMF, we specialize in providing expert consultation and innovative
        strategies for the modernization of mainframe applications. Our core
        mission is to assist organizations in transforming their legacy mainframe
        systems into agile, efficient, and future-ready platforms. We deliver
        comprehensive project management services to ensure seamless
        execution and achievement of modernization objectives.
      </p>
      <div className="explore_section_container">
        <div className="left_card">
          <Swiper
            spaceBetween={30}
            slidesPerView={1}
            autoplay={{ delay: 10000 }}
            loop
            pagination={{ clickable: true }}
            navigation
            modules={[Pagination, Navigation, Autoplay]}
          >
            <SwiperSlide>
              <div className="card">
                <img src={LeftImg} alt="left_Image" className="swiper-image" />
                <div className="content">
                  <h2 className="content_heading">Innovation.</h2>
                  <p className="content_para">
                    Innovation turns dreams into reality, bridging imagination with
                    tangible outcomes.
                    Turning bold ideas into breakthrough realities.
                  </p>
                </div>
              </div>
            </SwiperSlide>
            {exploreUsLeft.map((item, index) => (
              <SwiperSlide key={index}>
                <div className="card">
                  <img src={item.img} alt={`Slide ${index + 1}`} className="swiper-image" />
                  <div className="content">
                    <h2 className="content_heading">{item.title}</h2>
                    <p className="content_para">{item.description}</p>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default Explore;
