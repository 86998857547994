import styled from "styled-components";

import BG from "../assets/images/Untitled.jpeg";
// import BG from "../assets/images/mainframe3.png";

export const Front = styled.div`
  height: 100vh;
  width: 100vw;

  background-image: url(${BG});
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: cover;
  background-size:cover;
  filter: blur(1px)


  @media screen and (max-width: 596px){
    object-fit: center;
    background-position: center;
    background-size:cover;
    position: relative;
    top:0%
    
  }
`;
