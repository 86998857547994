import React from "react";
import "./Digital.css";

const Digital = () => {
  return (
    <div className="digital_container" data-aos="fade-left">
      <div className="digital_heading">
        <h1 className="digital_head" data-aos="fade-right">
          Domain <span className="color">Expertise</span>
        </h1>
        <p className="digital_para">
          {" "}
           MCDMF - Mainframe Services , we believe in providing personalized IT solutions to our clients. We understand that every business is unique, and so are its IT needs. That's why we work closely with our clients to understand their requirements and provide tailored solutions that fit their needs and budget. We also offer flexible pricing models to ensure that our services are accessible to all businesses.
        </p>
      </div>
      <div className="digital_card_container">
        {/* services card */}
        {/* <div className="digital_card">
          <div className="digital_card_top">
            <h2 className="digital_card_top_head">Services</h2>
          </div>
          <div className="digital_card_mid">
            <ul className="digital_card_points">
              <li className="digital_card_points">
              Integrated Loans Processing
                            </li>
              <li className="digital_card_points">
              Mortgages              </li>
              <li className="digital_card_points">
              Integrated Deposits Systems
              </li>
              <li className="digital_card_points">
              Cards and Merchants
              </li>
              <li className="digital_card_points">
              Payments
              </li>
              <li className="digital_card_points">
              High volume Transactions
              </li>
              <li className="digital_card_points">
              Customer Systems
              </li>
   
            </ul>
          </div>
          <div className="digital_card_bottom"></div>
        </div> */}
        {/* consulting card */}
        {/* <div className="digital_card">
          <div className="digital_card_top bg_color bg_color">
            <h2 className="digital_card_top_head"> Service Catalog</h2>
          </div>
          <div className="digital_card_mid"> */}
          {/* <h4>Advance Training on ZOS Mainframe:</h4> */}
            {/* <ul className="digital_card_points">
              <li className="digital_card_points">
              Z/OS
              </li>
              <li className="digital_card_points">
              Mainframe Modernisation tools
              </li>
              <li className="digital_card_points">
              COBOL
              </li>
              <li className="digital_card_points">
              DB2
              </li>
              <li className="digital_card_points">
              IMS
              </li>
              <li className="digital_card_points">
              Assembler
              </li>
              <li className="digital_card_points">
              HOGAN
              </li>
              <li className="digital_card_points">
              JCL
              </li>
              <li className="digital_card_points">
              Endevor
              </li> 
               <li className="digital_card_points">
              Omegamon
              </li>
              <li className="digital_card_points">
              FileAID

              </li>
         
            </ul>
          </div> */}
          {/* <div className="digital_card_bottom bg_color"></div>
        </div> */}
        {/* product card */}
        {/* <div className="digital_card">
          <div className="digital_card_top bg_color_1">
            <h2 className="digital_card_top_head">Product</h2>
          </div>
          <div className="digital_card_mid">
            <ul className="digital_card_points">
              <li className="digital_card_points">
                Innovative product development to meet market demands.
              </li>
              <li className="digital_card_points">
                Product design focused on usability and functionality.
              </li>
              <li className="digital_card_points">
                Regular updates based on customer feedback.
              </li>
              <li className="digital_card_points">
                Emphasis on quality, scalability, and ongoing support
              </li>
            </ul>
          </div>
          <div className="digital_card_bottom bg_color_1"></div>
        </div> */}
      </div>
    </div>
  );
};

export default Digital;
