import React, { useEffect, useState } from "react";

import { FaArrowDown } from "react-icons/fa6";
import { GiThink } from "react-icons/gi";
import {AiOutlineFundProjectionScreen} from "react-icons/ai";
import { GiSkills } from "react-icons/gi";


import "./index.css";

import { Front } from "../styledComponent";
import { servicesList } from "../../constants/index";
import { LiaCertificateSolid } from "react-icons/lia";
import { GrDomain, GrTechnology } from "react-icons/gr";
import { useLocation, useParams } from "react-router-dom";

const Services = () => {
  const handleButtonClick = () => {
    // Scroll down by 100vh
    window.scrollTo({
      top: window.innerHeight,
      behavior: "smooth",
    });
  };

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const serviceId = searchParams.get("id");

  useEffect(() => {
    if (serviceId) {
      const element = document.getElementById(`service-${serviceId}`);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
        element.classList.add("highlight");
        setTimeout(() => {
          element.classList.remove("highlight");
        }, 2000);
      }
    }
  }, [serviceId]);
  return (
    <>

        <div className="services-page" data-aos="fade-down">     

          {/* <h1 className="typewriter-text1">Our Services</h1> */}
          <h1 className="our_service_heading">
            Our <span className="color">Services</span>
          </h1>
          <p>
          At MCDMF, we specialize in providing expert consultation and innovative
strategies for the modernization of mainframe applications. Our core
mission is to assist organizations in transforming their legacy mainframe
systems into agile, efficient, and future-ready platforms. We deliver
comprehensive project management services to ensure seamless
execution and achievement of modernization objectives.
          </p>
          <div className="services-page-buttons">
            {/* <button className="arrow-button" onClick={handleButtonClick}>
              Explore Our Services
              <span className="arrow-icon">
                <FaArrowDown />
              </span>
            </button> */}
          </div>
        </div>
      <div className="our_services_section"data-aos="fade-right">
        <div className="our_service">
          
          <p className="our_service_para">
            Empowering businesses through tailored IT solutions, driven by
            innovation and expertise.
          </p>
        </div>
        <div className="our_service_content" data-aos="fade-left">
          {servicesList.map((item) => (
                   <div
                   className="our_service_card"
                   key={item.id}
                   id={`service-${item.id}`}
                 >
                   <div className="icon">
                {/* <img src={item.IconBase}  /> */}
                {item.IconBase === GiThink && <GiThink size={50} />}
                {item.IconBase === AiOutlineFundProjectionScreen && <AiOutlineFundProjectionScreen size={50} />}
                {item.IconBase === GiSkills && <GiSkills size={50} />}
                {item.IconBase === LiaCertificateSolid && <LiaCertificateSolid size={50} />}
                {item.IconBase === GrDomain && <GrDomain size={50} />}
                {item.IconBase === GrTechnology && <GrTechnology size={50} />}

                {/* <item.IconBase size={50}/> */}
              </div>
              <div className="service_card_content">
                <h3 className="card_heading">{item.title}</h3>
                <p className="card_para">{item.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      
    </>
  );
};

export default Services;
