// service section SVG import
// import WEBSVG from "../assets/svg/web-development-svgrepo-com.svg";
// import MOBILESVG from "../assets/svg/mobile-development-coding-code-xcode-svgrepo-com.svg";
// import SOFTWARESVG from "../assets/svg/rectangle-code-svgrepo-com.svg";
// import CLOUDSVG from "../assets/svg/cloud-computing-data-svgrepo-com.svg";
// import CONSULTINGSVG from "../assets/svg/layout-app-user-svgrepo-com.svg";
// import SUPPORTSVG from "../assets/svg/support-svgrepo-com.svg";

// about us SVG imports
import AdvantageSVG from "../assets/svg/advantage.svg";
import MissionSVG from "../assets/svg/mission.svg";
// import VisionSVG from "../assets/svg/vision.svg";
import { GiThink } from "react-icons/gi";
import { AiOutlineFundProjectionScreen } from "react-icons/ai";
import { GiSkills } from "react-icons/gi";
import { LiaCertificateSolid } from "react-icons/lia";
import { GrDomain } from "react-icons/gr";
import { GrTechnology } from "react-icons/gr";







// explore Section images
import ExperienceImg from "../assets/images/ethical integinity.jpg";
import InsightImg from "../assets/images/image (7).jpg";
import BusinessAccelerateImg from "../assets/images/accountability.jpg";
import AssureImage from "../assets/images/inclusiveness.jpg";
// import { IconBase } from "react-icons";

// menu items
export const menuItemsList = [
  {
    title: "Home",
    path: "/",
  },
  {
    title: "About",
    path: "/about",
  },
  {
    title: "Services",
    path: "/services",
  },
  {
    title:"Events",
    path:"/events",
  },
  {
    title: "Contact",
    path: "/contact",
  },

];
// choose us list
export const chooseUsList = [
  {
    title: "STEP-1",
    heading: " Expertise",
    description:
      "Our team of seasoned professionals brings extensive experience in mainframe systems and modernization",
  },
  {
    title: "STEP-2",
    heading: "Customized Solutions",
    description:
      " We offer tailored strategies and solutions that align with your specific business needs and goals",
  },
  {
    title: "STEP-3",
    heading: "Commitment to Excellence",
    description:
    " We are dedicated to delivering high quality services and achieving optimal outcomes for our clients. "
  },
  {
    title: "STEP-4",
    heading: "Future-focused Training",
    description:
      `Our comprehensive training programs
      are designed to equip both new graduates and experienced
      professionals with cutting-edge skills and knowledge.
      `,
  },
  {
    title: "STEP-5",
    heading: "Consultation and Strategy Development    ",
    description:
`Assessment and Analysis: In-depth evaluation of existing
mainframe systems to identify modernization opportunities.`  
},
  {
    title: "STEP-6",
    heading: "Strategic Roadmap",
    description:
`Tailored strategies to modernize
applications, enhancing performance, scalability, and
integration capabilities.`  },
  {
    title: "STEP-7",
    heading: "Cutting-Edge Tech Incubation",
    description:
      "Leverage partnerships with tech incubators and research institutions to bring the latest technological innovations to client projects.",
  },
  {
    title: "STEP-8",
    heading: "Technology Implementation",
    description:
` Leveraging the latest tools
and technologies to revitalize legacy systems.`  },
  {
    title: "STEP-9",
    heading: "Project Delivery",
    description:
`Full Lifecycle Management: From planning and design to
implementation and maintenance, we manage every aspect of
the modernization process.`  },
  {
    title: "STEP-10",
    heading: "Risk Mitigation",
    description:
`Proactive identification and management of
potential risks to ensure smooth transitions.`
  },
  {
    title: "STEP-11",
    heading: "Quality Assurance",
    description:
`Rigorous testing and validation to
guarantee the reliability and efficiency of modernized
applications.`
  },

];

// service section list
export const servicesList = [
  {
    id: 1,
    title: "Consultation and Strategy Development",
    IconBase:  GiThink,
    description:<div>
      <h3>
Assessment and Analysis:</h3> <p> In-depth evaluation of existing
mainframe systems to identify modernization opportunities.</p>
 <h3>Strategic Roadmap:</h3> <p> Tailored strategies to modernize
applications, enhancing performance, scalability, and
integration capabilities.</p>
<h3> Technology Implementation:</h3> <p> Leveraging the latest tools
and technologies to revitalize legacy systems.</p></div>
 },
  {
    id: 2,
    title: "Project Delivery",
    IconBase: AiOutlineFundProjectionScreen,
    description:<div>
<h3>Full Lifecycle Management:</h3> <p>From planning and design to
implementation and maintenance, we manage every aspect of
the modernization process.</p>
<h3>
Risk Mitigation:</h3> <p> Proactive identification and management of
potential risks to ensure smooth transitions.</p>
<h3>
 Quality Assurance:</h3> <p> Rigorous testing and validation to
guarantee the reliability and efficiency of modernized
applications.</p></div> },
  {
        id: 3,
    title: "Mainframe Skill Development Courses:",
    IconBase: GiSkills,
    description:<div><b>   Industry Connect Programs for College Graduates:</b>
      <b>   Skill Development:</b> <p>Providing foundational and
    advanced training in mainframe technologies to equip
    graduates with essential skills.</p>
    <b> Real-world Exposure:</b> <p>Hands-on projects and industry
    interaction to bridge the gap between academia and the
    corporate world.</p>
    </div>

 
  },
  {
    id: 4,
    title: "Advanced Training for Organizational Workforces",
    IconBase: LiaCertificateSolid,
    description:
    <div>
      <h3> Upskilling Programs:</h3> <p> Intensive courses designed to
enhance the capabilities of current mainframe
professionals.</p>
<h3> Modernization Techniques:</h3> Training on the latest
methodologies and tools to prepare the workforce for
modernizing legacy systems.
    </div>
  },
  {
    id: 5,
    title: "Domain Expertise",
    IconBase: GrDomain,
    description:
<div>
  <h3>Services</h3>
  <h4>Integrated Loans Processing</h4>
  <h4>Mortgages</h4>
  <h4>Integrated Deposits Systems</h4>
  <h4>Cards and Merchants</h4>
  <h4>Payments</h4>
  <h4>High volume Transactions</h4>
  <h4>Customer Systems</h4>


</div>
  },
  {
    id: 6,
    title: "Technology Expertise    ",
    IconBase: GrTechnology,
    description:<div>
    <h2>Service Catalog</h2>
    <h3>Advance Training on ZOS Mainframe</h3>
    <h4>Z/OS</h4>
    <h4>Mainframe Modernisation tools</h4>
    <h4>COBOL, CICS, DB2,</h4>
 <h4>IMS, Assembler, HOGAN,</h4>
    <h4>JCL, Endevor, Omegamon,</h4>
    <h4>FileAID</h4>

    </div>
  },
];
// about us top list
export const aboutList = [
  {
    title: "Our Advantages",
    svg: AdvantageSVG,
    description:
    `At MCDMF - Mainframe Services , we believe in providing personalized IT solutions to our clients. We understand that every business is unique, and so are its IT needs. That's why we work closely with our clients to understand their requirements and provide tailored solutions that fit their needs and budget. We also offer flexible pricing models to ensure that our services are accessible to all businesses.`
  },
  {
    title: "Our Mission",
    svg: MissionSVG,
    description:
`With years of experience in the Mainframe industry, we have honed our skills in providing a wide range of Mainframe services.Our team stays updated with the latest technologies and industry trends to provide the best solutions to our clients.`  },
  // {
  //   title: "Our Vision",
  //   svg: VisionSVG,
  //   description:
  //     "We strive to make a meaningful impact by enhancing the performance of your organization through dependable business insights, innovative thinking, cutting-edge design, and the latest offerings available.",
  // },
];

// what we offer
export const whatWeOffer = [
  { title: "Integrated Loans Processing", color: "#2980b9" }, // Dark Blue
  { title: "Mortgages", color: "#27ae60" }, // Green
  { title: "Integrated Deposits Systems", color: "#8e44ad" }, // Purple
  { title: "Cards and Merchants", color: "#f39c12" }, // Orange
  { title: "Payments", color: "#e74c3c" }, // Red
  { title: "High volume Transactions", color: "#3498db" }, // Blue
  { title: "Z/OS", color: "#2c3e50" }, // Dark Blue
  { title: "Advance Training on ZOS Mainframe", color: "#d35400" }, // Orange
  { title: "Mainframe Modernisation tools", color: "#16a085" }, // Green
  { title: "COBOL", color: "#34495e" }, // Dark Gray
  { title: "CICS", color: "#e67e22" }, // Orange
  { title: "DB2", color: "#c0392b" }, // Dark Red
  { title: "IMS", color: "#f1c40f" }, // Yellow
  { title: "Assembler", color: "#7f8c8d" }, // Gray
  { title: "HOGAN", color: "#9b59b6" }, // Purple
  { title: "JCL", color: "#1abc9c" }, // Turquoise
  { title: "Endevor", color: "#2980b9" }, // Dark Blue
  { title: "Omegamon", color: "#27ae60" }, // Green
  { title: "FileAID", color: "#8e44ad" }, // Purple



];

export const exploreUsLeft = [
  {
    title: "Ethical Integrity",
    img: ExperienceImg,
    description:
      "We hold ourselves to the highest ethical standards, ensuring transparency, honesty, and fairness in all our actions.",
  },
  {
    title: " Service",
    img: InsightImg,
    description:
      `
      
      Your satisfaction, our passion.
      Consultation and Strategy Development,
       Project Delivery, and
        Mainframe Skill Development Courses`,
  },
  {
    title: "Accountability",
    img: BusinessAccelerateImg,
    description:
      `
      Our promise, your peace of mind. Proactive identification and management of
      potential risks to ensure smooth transitions.`,
  },
  {
    title: "Inclusiveness",
    img: AssureImage,
    description:
      "We embrace diversity in ethnicity, age, gender and abilities, fostering equality and empowerment for all.",
  },
];
