import React from "react";

import "./index.css";
import { Link, useNavigate } from "react-router-dom";
import { Front } from "../styledComponent";
import { FaArrowDown } from "react-icons/fa6";
import { Chrono } from "react-chrono";
import ChooseUs from "./ChooseUs";
import { chooseUsList, servicesList, whatWeOffer } from "../../constants/index";
import ExplorePage from "../explore/Explore";
import Digital from "../Digital_Solutions/Digital";
import { GiSkills, GiThink } from "react-icons/gi";
import { AiOutlineFundProjectionScreen } from "react-icons/ai";
import { GrDomain, GrTechnology } from "react-icons/gr";
import { LiaCertificateSolid } from "react-icons/lia";

const Home = () => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    // Scroll down by 100vh
    window.scrollTo({
      top: window.innerHeight,
      behavior: "smooth",
    });
  };
  const handleServiceClick = (id) => {
    navigate(`/services/${id}`);
  };

  return (
    <>
      {/* Hero section */}
      <Front>
        <div className="heading">
          <h5 style={{color:"#fff"}} >Welcome to,</h5>
          <h1 className="typewriter-text"><span className="highlight">MCDMF</span> - Mainframe Modernization Services</h1>
          <h3 className="slide-in" style={{color:"#fff"}}>Our promise, your peace of mind</h3>
          <div className="home-buttons">
            <Link to="/contact">
              <button className="lets-talk">Let's Talk</button>
            </Link>
            <button className="explore-us" onClick={handleButtonClick}>
              Explore Us
              <span className="arrow-icon">
                <FaArrowDown />
              </span>
            </button>
          </div>
        </div>
        <div className="services_cards">
        {/* <Link   to="/services">    */}
        <div className="card1">
       {servicesList.map((item) => (
                                    <div className="our_service_card1">
                                                          <Link to={`/services?id=${item.id}`} key={item.id}>



            {/* <div className="our_service_card1" key={item.id} onClick={() => handleServiceClick(item.id)}> */}
            <div className="icon1">
                {/* <img src={item.IconBase}  /> */}
                {item.IconBase === GiThink && <GiThink size={50} />}
                {item.IconBase === AiOutlineFundProjectionScreen && <AiOutlineFundProjectionScreen  size={50} />}
                {item.IconBase === GiSkills && <GiSkills  size={50} />}
                {item.IconBase === LiaCertificateSolid && <LiaCertificateSolid size={50} />}
                {item.IconBase === GrDomain && <GrDomain size={50} />}
                {item.IconBase === GrTechnology && <GrTechnology size={50} />}

                {/* <item.IconBase size={50}/> */}
              </div>
              <div className="service_card_content">
                <h3 className="card_heading1">{item.title}</h3>
                {/* <p className="card_para">{item.description}</p> */}
              </div>
              </Link>

            </div>


          ))}
        
          </div>
          {/* </Link> */}

          {/* <div className="card1"></div>
          <div  className="card1"></div>
          <div  className="card1"></div>
          <div  className="card1"></div>
          <div  className="card1"></div> */}


      
          
        </div>
      </Front>
      {/* explore section */}
      <ExplorePage />
      {/* Digital Solutions */}
      {/* <Digital/> */}
      {/* <div className="what_we_offer_text">
          <h1 className="what_we_offer_heading">
            What We <span className="color">Offer</span> ?
          </h1>
          <p className="what_we_offer_subheading">
            At MCDMF-Hogan services, we stand prepared to deliver exceptional service...
          </p>
        </div>

      <div className="what_we_offer_cards">
          {whatWeOffer.map((item) => (
            <div
              className="what_we_offer_card"
              style={{ backgroundColor: item.bgcolor }}
            >
              <span
                className="what_we_offer_card_heading"
                style={{ color: item.color }}
              >
                {item.title}
              </span>
            </div>
          ))}
        </div>
 */}
      {/* Why choose us section */}
      <div className="why-choose-us" data-aos="fade-right" >
        <h1 className="why-choose-us-head" data-aos="fade-left">
          WHY ONLY <span className="color">US</span>?
        </h1>
        <p className="why-choose-us-description" >
       {/* <h2>Expertise</h2> <p>        Our team of seasoned professionals brings extensive
experience in mainframe systems and modernization.</p>
<h2>Customized Solutions</h2>
<p> We offer tailored strategies and solutions
that align with your specific business needs and goals.</p>
<h2>Commitment to Excellence</h2>
<p> We are dedicated to delivering highquality services and achieving optimal outcomes for our clients.
</p>
<h2>Future-focused Training</h2>
<p>Our comprehensive training programs
are designed to equip both new graduates and experienced
professionals with cutting-edge skills and knowledge.</p> */}
At MCDMF - Mainframe Services , we believe in providing personalized
 IT solutions to our clients. We understand that every business is unique, and so are its IT needs. That's why we work closely with our clients to understand their requirements and provide tailored solutions that fit their needs and budget.
 We also offer flexible pricing models to ensure that our services are accessible to all businesses.

        </p>
        <div style={{ width: "100vw", background:"whitesmoke" }}  data-aos="fade-left" >
          <Chrono
            items={chooseUsList}
            mode="VERTICAL_ALTERNATING"
            disableClickOnCircle={true}
            cardHeight="auto"
            activeItemIndex=""
            hideControls={true}
            slideShowType="reveal"
            disableInteraction={false}
            disableNavOnKey={true}
            disableToolbar={true}
            className={{ cardTitle: "my-card-title" }}
            theme={{
              primary: "#20d3d3",
              secondary: "#20d3d3",
              titleColor: "#2E3192",
              cardTitle: "#22222",
              cardSubtitle: "#312f36",
              
            
            }}
          >
            {chooseUsList.map((each,index) => {
              return <ChooseUs  key={index} item={each} />;
            })}
          </Chrono>
         
        </div>
      </div>
    </>
  );
};

export default Home;
