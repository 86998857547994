import React, { useState, useEffect } from "react";
import Logo from "../../assets/images/logo of client.webp";
import {
  FaArrowUp,
  FaTwitter,
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaWhatsapp,
} from "react-icons/fa";
import { IoLogoYoutube } from "react-icons/io5";
import { Link } from "react-router-dom";
import "./index.css";

const Footer = () => {
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [selectedItem, setSelectedItem] = useState(null); // Keeps track of the currently selected menu item


  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentYear(new Date().getFullYear());
    }, 60000); // Update every minute to catch year change
    return () => clearInterval(interval);
  }, []); // Run only once on component mount

  const companyMailId = "conatact@mcdmf.com";
  const companyNumber =  "9503018251";

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="footer" data-aos="fade-left">
      <div className="footer-content">
        <div className="footer-b2y">
           <div className="logo">
        <Link to="/" onClick={() => setSelectedItem(null)}>
        <img className="logo_img" src={Logo}/>
        <h1 className="logo_img1">MCDMF</h1>

          {/* <img src={Logo} alt="logo" className="logo_img" /> */}
        </Link>
        <span className="logo_sub1">Mainframe Services</span>
      </div>
          {/* <p>
          MCDMF - Mainframe Services, we believe in providing personalized IT solutions to our clients. We understand that every business is unique, and so are its IT needs. That's why we work closely with our clients to understand their requirements and provide tailored solutions that fit their needs and budget. We also offer flexible pricing models to ensure that our services are accessible to all businesses
          </p> */}
          <div className="footer-social-links">
            <a
              // href="https://www.facebook.com/b2yinfy"
              // href=""
              // target="_blank"
              // rel="noopener noreferrer"
              className="facebook"
            >
              <FaFacebookF className="social_icons" />
            </a>

            <a
              // href="https://www.instagram.com/b2yinfy"
              // href=""

              // target="_blank"
              // rel="noopener noreferrer"
              className="instagram"
            >
              <FaInstagram className="social_icons" />
            </a>
            <a
              href="https://www.linkedin.com/company/mcdmf-tangible-ai-software-services/"
              // href=""
              target="_blank"
              rel="noopener noreferrer"
              className="linkedin"
            >
              <FaLinkedinIn className="social_icons" />
            </a>
            <a
              // href="https://twitter.com/b2yinfy"
              // href=""

              // target="_blank"
              // rel="noopener noreferrer"
              className="twitter"
            >
              <FaTwitter className="social_icons" />
            </a>
            <a
              // href="https://www.youtube.com/watch?v=OSG3sdSiOi8"
              // href=""

              // target="_blank"
              // rel="noopener noreferrer"
              className="youtube"
            >
              <IoLogoYoutube className="social_icons white" />
            </a>
            <a
              href="https://wa.me/919503018251              "
              // href=""
              target="_blank"
              rel="noopener noreferrer"
              className="whatsapp"
            >
              <FaWhatsapp className="social_icons white" />
            </a>
          </div>
        </div>

        <div className="footer-company">
          <h1>COMPANY</h1>

          <Link to="/about">
            <p onClick={handleScrollToTop}>About Us</p>
          </Link>
          <Link to="/services">
            <p onClick={handleScrollToTop}>Our Services</p>
          </Link>
          <Link to="/contact">
            <p onClick={handleScrollToTop}>Contact Us</p>
          </Link>
        </div>

        <div className="footer-support">
          <h1>CONTACT US</h1>
          <a href={`mailto:${companyMailId}`}>contact@mcdmf.com</a>
          <a href={`tel:${companyNumber}`}>IN +91 9503018251</a>
          <a href={`tel:${companyNumber}`}>UK +44 7930 929806</a>

        </div>
        <div className="footer-address">
          <h1>REGISTERED ADDRESS</h1>
          <address>
          602 Malpani Greens, pune, 411057
            {/* <br />
            Sarjapur - Marathahalli Road,
            <br />
            Doddakannelli, Bengaluru, Karnataka 560035 */}
          </address>
        </div>
        <button className="arrow-up" onClick={handleScrollToTop}>
          <FaArrowUp />
        </button>
      </div>

      <div className="copy-rights">
        &copy; {currentYear} All rights reserved. {" "}
        <span>MCDMF - Mainframe Modernization Services </span>
      </div>
    </div>
  );
};

export default Footer;
