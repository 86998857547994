import React from "react";

import { FaArrowDown } from "react-icons/fa6";
import { aboutList } from "../../constants/index";
import { whatWeOffer } from "../../constants/index";
import OurStoryImg from "../../assets/images/aboutimg1.jpg";
import OurMissionImg from "../../assets/images/about-us-2.jpg";
import Drchary from "../../assets/images/chary.jpg"
import karthik from "../../assets/images/karthikeyan.webp"
import nisanth from "../../assets/images/nishant.webp"

import "./index.css";
import { Front } from "../styledComponent";
import Digital from "../Digital_Solutions/Digital";
// import { Value } from "./styledComponent";
const About = () => {
  const handleButtonClick = () => {
    // Scroll down by 100vh
    window.scrollTo({
      top: window.innerHeight,
      behavior: "smooth",
    });
  };

  return (
    <>
    
        <div className="about-heading">
          {/* <h5 className="typewriter-text1">OUR STORY</h5>
          <h1 className="typewriter-text1" >About US</h1> */}
          {/* <p>
          Founded by dedicated IT enthusiasts, we are starting this venture with
the ambition to grow, innovate and deliver unparalleled Mainframe
services to businesses. Our core belief is in building lasting partnerships
with our clients, helping them achieve their business goals.
          </p> */}
          {/* <div className="about-buttons">
            <button className="arrow-button" onClick={handleButtonClick}>
              Explore Us
              <span className="arrow-icon">
                <FaArrowDown />
              </span>
            </button>
          </div> */}
        </div>
      
      <div className="background-screen">
      {/* <div className="advantages_Container" data-aos="fade-up">
        {aboutList.map((item) => (
          <div className="advantages_card" >
            <div className="svg_icons">
              <img src={item.svg} alt="advantage" width={60} height={60} />
            </div>
            <div className="advantages_text">
              <h2 className="advantages_head">{item.title}</h2>
              <p className="advantages_para">{item.description}</p>
            </div>
          </div>
        ))}
      </div> */}

      <div className="storyContainer">
        <div className="storySection" data-aos="fade-up">
          <div className="story_img">
            <img src={OurStoryImg} alt="Our_Story" className="storyimg" />
          </div>
          <div className="storyText">
            <div className="storyText_head_section">
              <h2 className="our_story_head">
                Our<span className="color space">Story</span>
              </h2>
              <span className="out_story_subheading">
                From Vision to Venture
              </span>
            </div>
            <p className="our-story_para">
            Founded by dedicated IT enthusiasts, we are starting this venture with
the ambition to grow, innovate and deliver unparalleled Mainframe
services to businesses. Our core belief is in building lasting partnerships
with our clients, helping them achieve their business goals.
            </p>
          </div>
        </div>
        <div className="storySection reverse" data-aos="fade-right">
          <div className="storyText">
            <div className="storyText_head_section">
              <h2 className="our_story_head">
                Our<span className="color space">Mission</span>
              </h2>
              <span className="out_story_subheading">
                Empowering Your Modernization Journey
              </span>
            </div>
            <p className="our-story_para">
            Our vision is to become the leading provider of mainframe modernization
solutions and training programs, ensuring the sustainability and evolution
of mainframe technologies. By fostering a new generation of skilled
mainframe professionals, we aim to bridge the current skills gap and
support organizations in their journey towards digital transformation.
            </p>
          </div>
          <div className="story_img">
            <img src={OurMissionImg} alt="Our_Story" className="storyimg" />
          </div>
          
        </div>
        <div className="storySection" data-aos="fade-left">
          <div className="story_img">
          <h2 className="our_story_head">
                Our<span className="color space">Team</span>
              </h2>
              <br />

            <img src={Drchary} alt="Our_Story" className="storyimg1" />
          </div>
          <div className="storyText">
            <div className="storyText_head_section">
              <h2 className="our_story_head">
                Dr.<span className="color space">Chary Mudumbi</span>
              </h2>
              <span className="out_story_subheading">
              Principal/Lead consultant at MCDMF
              </span>
            </div>
            <p className="our-story_para">
            Dr Chary Mudumbi - Principal/Lead consultant at MCDMF is a veteran in IBM mainframe 
            world and has over three decades of experience in MVS, IMS and CICS world and expertise in Hogan Umbrella, AZURE cloud architecture. He excels in banking core systems automation, security implementation, performance and tuning,
             mainframe transformation and modernisation, winning awards for his technical proficiency and dedication.
           
            </p>
          </div>
        </div>
        <div className="storySection reverse" data-aos="fade-right">
          <div className="storyText">
            <div className="storyText_head_section">
              <h2 className="our_story_head">
            <span className="color space">  Nishant</span>
              </h2>
              <span className="out_story_subheading">
              Head of Sales at MCDMF 
              </span>
            </div>
            <p className="our-story_para">
            Nishant is a result driven Sales and Marketing Professional having over 10 years of industry experience with global exposure which provides a solid, efficient and adaptable approach to multiple markets and brands.



In her decade long career, she has been associated with multiple organizations in various capacities across different geographies giving her a holistic view of industry including extensive experience in presales and sales roles.
 
            </p>
          </div>
          <div className="story_img">
            <img src={nisanth} alt="Our_Story" className="storyimg" />
          </div>
          
        </div>

        {/* <div className="storySection" data-aos="fade-left">
          <div className="story_img">
            <img src={karthik} alt="Our_Story" className="storyimg" />
          </div>
          <div className="storyText">
            <div className="storyText_head_section">
              <h2 className="our_story_head">
              Karthikeyan<span className="color space">Santhanam</span>
              </h2>
              <span className="out_story_subheading">
              seasoned Hogan mainframe specialist words of wisdom - MCDMF
              </span>
            </div>
            <p className="our-story_para">
            Our mentor Karthikeyan Santhanam a seasoned Hogan mainframe specialist words of wisdom - MCDMF Mainframe Services
             founders are poised to start a promising venture. With their innovative approach, their groundbreaking spirit and strong work ethic are notable. Their inspiring vision forecasts a bright future for the startup. As they
             gear up for this venture, their commitment establishes a robust foundation for a journey that's set to revolutionize their field.
          
           
            </p>
          </div>
        </div> */}
       
      </div>

      {/* What we Offer */}

      <div className="what_we_offer_container" data-aos="fade-up">
        {/* <div className="what_we_offer_text">
          <h1 className="what_we_offer_heading">
            What We <span className="color">Offer</span> ?
          </h1>
          <p className="what_we_offer_subheading">
            At MCDMF-Hogan services, we stand prepared to deliver exceptional service...
          </p>
        </div> */}

        {/* <div className="what_we_offer_cards">
          {whatWeOffer.map((item) => (
            <div
              className="what_we_offer_card"
              style={{ backgroundColor: item.bgcolor }}
            >
              <span
                className="what_we_offer_card_heading"
                style={{ color: item.color }}
              >
                {item.title}
              </span>
            </div>
          ))}
        </div> */}
      </div>
      </div>
    </>
  );
};

export default About;
