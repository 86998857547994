// import React from 'react'
// import "./index.css"
// import { Front } from '../styledComponent'
// import { Link } from 'react-router-dom'
// import { FaArrowDown } from 'react-icons/fa6'

// const Events = () => {
//     const handleButtonClick = () => {
//         // Scroll down by 100vh
//         window.scrollTo({
//           top: window.innerHeight,
//           behavior: "smooth",
//         });
//       };
//   return (
//     <>
//     <Front>
//         <div className="heading">
//           <h5 >Welcome to,</h5>
//           <h1 className="typewriter-text">MCDMF - Hogan Services</h1>
//           <h3 className="slide-in">Our promise, your peace of mind</h3>
//           <div className="home-buttons">
//             <Link to="/contact">
//               <button className="lets-talk">Let's Talk</button>
//             </Link>
//             <button className="explore-us" onClick={handleButtonClick}>
//               Explore Us
//               <span className="arrow-icon">
//                 <FaArrowDown />
//               </span>
//             </button>
//           </div>
//         </div>
//       </Front>
//       <div className="youtube-video-container">
//         <iframe
    
//           width="960"
//           height="515"
//           src="https://www.youtube.com/embed/dQw4w9WgXcQ?autoplay=1&mute=1" // Replace with your YouTube video link
//           title="YouTube video player"
//           frameBorder="0"
//           allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
//           allowFullScreen


//         ></iframe>
//         </div>
     
//       </>
//   )
// }

// export default Events

// import React from 'react';
// import { Link } from 'react-router-dom';
// import { FaArrowDown } from 'react-icons/fa6';
// import { Swiper, SwiperSlide } from 'swiper/react';
// import 'swiper/css';
// import 'swiper/css/pagination';
// import 'swiper/css/navigation';
// import './index.css';
// import { Front } from '../styledComponent';

// import { Pagination, Navigation, Autoplay } from 'swiper/modules';
// import img1 from "../../assets/images/blog_eye-opening-mainframe-computers.jpg"
// import img2 from "../../assets/images/1.webp"
// import img3 from "../../assets/images/images.jpg"

// const Events = () => {
//   const handleButtonClick = () => {
//     window.scrollTo({
//       top: window.innerHeight,
//       behavior: 'smooth',
//     });
//   };

// //   const videos = [
// //     'https://www.youtube.com/watch?v=WOoGGxrQoP0&list=RDWOoGGxrQoP0&start_radio=1?autoplay=1&mute=1',
// //     'https://www.youtube.com/embed/3JZ_D3ELwOQ?autoplay=1&mute=1',
// //     'https://www.youtube.com/embed/L_jWHffIx5E?autoplay=1&mute=1',
// //   ];

// const videoLinks = [
//     "https://www.youtube.com/embed/D2qiVudg6ps",
//     "https://www.youtube.com/embed/8RLpTv2olVY",
//     "https://www.youtube.com/embed/zsyK0RLGLkw"
//   ];
//   const imageLinks = [
//     img1,
//     img2,
//     img3
//   ];

//   return (
//     <>
//       <Front>
//         <div className="heading">
//           <h5>Welcome to,</h5>
//           <h1 className="typewriter-text">MCDMF - Hogan Services Events</h1>
//           <h3 className="slide-in">Our promise, your peace of mind</h3>
//           <div className="home-buttons">
//             <Link to="/contact">
//               <button className="lets-talk">Let's Talk</button>
//             </Link>
//             <button className="explore-us" onClick={handleButtonClick}>
//               Our Stories
//               <span className="arrow-icon">
//                 <FaArrowDown />
//               </span>
//             </button>
//           </div>
//         </div>
//       </Front>
//       <div className="youtube-video-container">
//       <h2 className="our_story_head">
//                 Our<span className="color space">Stories</span>
//               </h2>
//         <Swiper
//           spaceBetween={30}
//           slidesPerView={1}
//           autoplay={{ delay: 10000 }}
//           loop
//           pagination={{ clickable: true }}
//           navigation
//           modules={[Pagination, Navigation, Autoplay]}
//         >
//           {videoLinks.map((link, index) => (
//             <SwiperSlide key={index}>
//               <iframe
//                 width="760"
//                 height="315"
//                 // src={video}
//                 src={`${link}?autoplay=1&mute=1`}
//                 title={`YouTube video player ${index + 1}`}


//                 // title={`YouTube video player ${index}`}
//                 frameBorder="0"
//                 allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
//                 allowFullScreen
//               ></iframe>
//             </SwiperSlide>
//           ))}
//         </Swiper>
        
//       </div>
//       <div className="swiper-container">
//       <h2 className="our_story_head">
//                 Our<span className="color space">Achievements</span>
//               </h2>
//         <Swiper
//           spaceBetween={30}
//           slidesPerView={1}
       
//           autoplay={{ delay: 3000 }}
//           loop
//           pagination={{ clickable: true }}
//           navigation
//           modules={[Pagination, Navigation, Autoplay]}
//         >
//           {imageLinks.map((link, index) => (
//             <SwiperSlide key={index}>
//               <img src={link} alt={`Image ${index + 1}`} />
//             </SwiperSlide>
//           ))}
//         </Swiper>
//       </div>
//     </>
//   );
// };

// export default Events;


import React from 'react'
import "./index.css"
// import comingsoon from "../../assets/coming-soon.jpeg"
import Events1 from '../../assets/images/events.jpg'

const Events = () => {
  return (
    <>
    <div className="events-container">
        {/* <img src={comingsoon} className='comingsoon'/> */}
        <img src={Events1} className='comingsoon'/>

      {/* <i className="animated-text " >Coming Soon <span style={{color:"orange"}}>...</span> </i> */}
    </div>
          <p  className='text'>At the "Joy of Giving" event, MCDMF brought smiles to 430 kids of a Pune government 
            school by providing them with notebooks, stationery, and lunch bags. The heartwarming initiative showcased MCDMF's commitment to community welfare, 
            emphasizing the importance of education and support for underprivileged children.</p>
            </>

  )
}

export default Events
